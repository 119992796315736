import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  .ant-input,
  .input--button__money_label {
    /* border-radius: 30px; */
    border: solid 1px #d9d9d9;
    height: 44px;
    color: #3f444d;
    font-family: 'Montserrat-Regular',sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding: 12px 22px;
    width: 100%;

    &::placeholder {
      font-family: 'Montserrat-Regular',sans-serif;
    }

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      background-color: #f5f5f5;
      :-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px #f5f5f5 inset !important;
      }
    }
    &::placeholder {
      color: #949494;
      font-size: 16px;
    }
  }
  .ant-input-affix-wrapper {
    /* border-radius: 30px; */
    /* border-radius: 100px; */
    border: solid 1px #d9d9d9;
    color: #3f444d;
    font-family: 'Montserrat-Regular',sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding: 0 9px 0 22px;
    &:focus {
      box-shadow: none;
    }
    &:disabled {
      background-color: #f5f5f5;
    }

    .ant-input-suffix {
      height: 32px;
      line-height: 32px;
      border-radius: 20px;
      background-color: #08ceac;
      padding: 0 26px;
      color: #fff;
      font-family: 'Montserrat-Regular',sans-serif;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      margin-top: 9px;
      cursor: pointer;
    }
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: solid 1px #d9d9d9;
    box-shadow: none;
    .ant-input {
      border-radius: 0;
    }
  }

  input.field-input {
    &:focus {
      outline: none;
    }
    &.c-fix,
    &:focus,
    &:not(:placeholder-shown) {
      ~ label.field-placeholder {
        width: max-content;
        left: 16px;
        top: -8px;
        font-size: 13px !important;
        font-family: 'Montserrat-Regular',sans-serif;
        font-weight: 300;
        background-color: transparent;
        backdrop-filter: blur(3px);
        color: #3f444d;
      }
    }
  }

  .input--button__label {
    padding-right: 120px;
  }

`;

export const SuffixBtn = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 105px;
  height: 32px;
  line-height: 32px;
  border-radius: 20px;
  background-color: #e5fffa;
  color: #08ceac;
  font-family: 'Montserrat-Regular',sans-serif;
  font-size: 14px;
  font-weight: 500;

  &:disabled {
    cursor: not-allowed;
    background-color: #d9d9d9;
    color: #ffffff;
  }
`;

export const TextLine = styled.label`
  width: auto;
  position: absolute;
  left: 16px;
  top: 25%;
  color: #949494;
  font-size: 16px !important;
  background-color: white;
  padding: 0 5px;
  transition: all 0.3s ease;
  pointer-events: none;

  &.show-text {
    width: max-content;
    left: 16px;
    top: -8px;
    font-size: 13px !important;
    font-family: 'Montserrat-Regular',sans-serif;
    font-weight: 300;
    background-color: transparent;
    backdrop-filter: blur(3px);
    color: #3f444d;
  }
  &.disabled {
    background: #f5f5f5;
  }
`;

export const TextSearch = styled.div`
  position: absolute;
  right: 11px;
  top: 6px;

  width: 105px;
  height: 32px;
  /* border-radius: 16px; */
  background-color: #4a79fd;
  cursor: pointer;

  font-family: 'Montserrat-Regular',sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 32px;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
`;

export const SearchIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 25%;
  width: auto;
  height: auto;
  background-color: white;
  cursor: pointer;
  padding: 0 0 10px 10px;
`;

export const BottomLine = styled.div`
  width: 100%;
  height: 41px;
  position: relative;
  border-bottom: solid 1px #d9d9d9;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    font-family: 'Montserrat-Regular',sans-serif;
    font-size: 16px;
    font-weight: 500;

    &.ant-input:focus {
      box-shadow: unset;
    }
  }
`;

export const TextLabel = styled.div`
  position: absolute;
  font-family: 'Montserrat-Regular',sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: right;
  color: #525356;
  background-color: transparent;
  backdrop-filter: blur(50px);
  right: 0;
  top: 0.5em;
`;

export const UnitText = styled.span`
  position: absolute;
  right: 16px;
  top: 12px;
  font-family: 'Montserrat-Regular',sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #3f444d;
`;
