import { mainAxios } from "libs/axios";

export const dashboardApi = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/dashboard/dashboards`,
    notError: true,
    requiresToken: true,
  });
};
