import styled from "styled-components";

export const Container = styled.div`

  input[type="file"], input[type="file"]:focus {
    width: 100%;
    outline: none;
}
  .file-upload-button {
    padding: 0 0;
  }

`