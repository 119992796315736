import { Button, Form, Input, Modal } from "antd";
import React from "react";

interface Props {
  title?: string;
  isOpen: boolean;
  onCancel: () => void;
  onOk?: (value: string) => void;
}

function ModalExportExcel({ title, isOpen, onCancel, onOk }: Props) {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    onOk?.(values?.reason);
    form.resetFields();
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={onCancel}
      // onOk={handleOk}
      footer={null}
    >
      <Form
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        layout="horizontal"
        initialValues={{}}
        form={form}
      >
        <Form.Item
          name="reason"
          rules={[
            {
              required: true,
              message: "필수항목을 입력해주세요.",
            },
          ]}
        >
          <Input placeholder="다운로드 사유" />
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: "center" }}>
            <Button type="primary" size="large" htmlType="submit">
              확인
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ModalExportExcel;
