import styled from "styled-components";

export const Container = styled.span`
  cursor: pointer;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
`;
