import { PlusOutlined } from "@ant-design/icons";
import { message, Modal, Upload, Button } from "antd";
import React, { useEffect, useState } from "react";

import type { RcFile } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";

import * as S from "./style";

type Data = {
  uid: string;
  name: string;
  url: string;
};

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface Props {
  getData?: (value: UploadFile[]) => void;
  fileListProps?: UploadFile[];
}

function ImageUpload(props: Props) {
  const { getData, fileListProps = [] } = props;

  const [defaultFileList, setDefaultFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  useEffect(() => {
    if (fileListProps && fileListProps.length > 0) {
      setDefaultFileList(fileListProps);
      getData?.(fileListProps);
    }
  }, [fileListProps]);

  const handleOnChange = ({ file, fileList, event }: any) => {
    console.log("fileList", fileList);

    const listAcceptedData: UploadFile[] = [];

    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].size / 1024 / 1024 <= 5 || !fileList[i].size) {
        listAcceptedData.push(fileList[i]);
      }
    }
    getData?.(listAcceptedData);
    setDefaultFileList(listAcceptedData);
  };

  const handleRemove = (file: UploadFile) => {
    const data = fileListProps?.filter((data) => data?.name !== file.name);
    getData?.(data);
  };

  const handlePreview = async (file: UploadFile) => {
    let imageFormat = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/svg",
    ]
    if (imageFormat.includes(file.type!)) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(
        file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
      );
    } else {
      window.open(file.url, "_blank");
    }
    console.log('file', file)
  };

  const handleCancel = () => setPreviewOpen(false);

  const beforeUpload = (file: any) => {
    if (file.size / 1024 / 1024 >= 5) {
      message.warning(
        `최대 5Mb 이하 파일만 업로드가 가능합니다. ${file.name} 파일을 다시 확인해주세요.`
      );
    }

    return false;
  };

  return (
    <S.Container>
      <Upload
        accept="file/*"
        onChange={handleOnChange}
        onPreview={handlePreview}
        onRemove={handleRemove}
        listType="picture-card"
        defaultFileList={defaultFileList}
        fileList={defaultFileList}
        className="image-upload-grid"
        maxCount={5}
        multiple
        beforeUpload={beforeUpload}
      >
        {
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        }
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </S.Container>
  );
}

export default ImageUpload;
