import React, { useEffect, useState } from "react";
import * as S from "./style";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";

import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { ShortText, TableAntd, ShortNumber } from "components";
import { getCollateralInformationAPI } from "apis";
import { MORTGAGE_STATUS, IN_STORAGE } from "common/constants/dataSelectLoan";
import { useHistory } from "react-router-dom";
import { UrlInternal } from "common/constants/endpoints";

interface Props {
  success?: boolean;
  open?: any;
  handleOpen?: any;
  type?: string;
  borrowerID?: string;
}

function PopUp(props: Props): React.ReactElement {
  return (
    <S.Container>
      <Confirm
        type={props.type}
        open={props.open}
        handleConfirm={() => {
          props?.handleOpen();
        }}
        borrowerID={props.borrowerID}
      />
    </S.Container>
  );
}

const Confirm = ({ type, open, handleConfirm, borrowerID }: any) => {
  const { t } = useTranslation();
  const [collateralInformation, setCollateralInformation] = useState<any>([]);
  const [totalItem, setTotalItem] = useState<any>();
  const history = useHistory();
  //  console.log('collateralInformation :>> ', collateralInformation);


  useEffect(() => {
    open ? (async function getLoanData() {
      const res: any = await getCollateralInformationAPI({
        loanManagerId: borrowerID,
      });
      if (res.data) {
        setCollateralInformation(res?.data);
        setTotalItem(res?.data.length);
      } else {
        setCollateralInformation([]);
        setTotalItem(0);
      }
    })()
      : setCollateralInformation([]);
  }, [borrowerID, open]);

  // useEffect(() => {
  //   if (open === false) {
  //     setCollateralInformation([]);
  //   }
  // }, [open]);


  const itemRender = (current: any, type: string, originalElement: any) => {
    if (type === "prev") {
      return <ArrowLeft />;
    }
    if (type === "next") {
      return <ArrowRight />;
    }
    return originalElement;
  };


  const dataSource = collateralInformation?.map(
    (collateralInfo: any, index: any) => {
      return {
        ...collateralInfo,
        key: index,
        image: [
          collateralInfo.imageNameWork[0]?.image_url,
          collateralInfo.imageNameWork[0]?.name,
        ],
        author_name: collateralInfo.authorName,
        name_product: collateralInfo.workName,
        initial_value: [
          collateralInfo.initialAmount,
          collateralInfo.initDensity,
        ],
        current_value: [
          collateralInfo.currentAmount,
          collateralInfo.currentDensity,
        ],
        current_allotment: collateralInfo.currentAllocateAmount,
        preserve_state: collateralInfo.storageStatus,
        mortgage_status: collateralInfo.mortgageStatus,
      };
    }
  );

  const storage_status = [
    {
      value: IN_STORAGE.IN_STORAGE,
      label: t("MORTGAGE_OPTION_IN_MAINTENANCE"),
    },
    {
      value: IN_STORAGE.LEASING,
      label: t("MORTGAGE_OPTION_IN_RENTAL"),
    },
    {
      value: IN_STORAGE.RETURNING_MID_BATCH,
      label: t("MORTGAGE_OPTION_RETURN_MIDDLE"),
    },
    {
      value: IN_STORAGE.RETURN_NORMAL,
      label: t("MORTGAGE_OPTION_RETURN_NORMAL"),
    },
  ];

  const mortgage_status_translate = [
    {
      value: MORTGAGE_STATUS.NORMAL,
      label: t("MORTGAGE_OPTION_NORMAL_MORTGAGE"),
    },
    {
      value: MORTGAGE_STATUS.PROCEED_COLLATERAL,
      label: t("MORTGAGE_OPTION_PROCEED_COLLATERAL"),
    },
    {
      value: MORTGAGE_STATUS.CANCEL,
      label: t("MORTGAGE_OPTION_CANCEL_MORTGAGE"),
    },
  ];
  const columns = [
    {
      title: t("IMAGE"),
      dataIndex: "image",
      key: "image",
      width: "120px",
      render: (image: any) => {
        return <img alt={image[1]} src={image[0]} height={50}></img>;
      },
    },
    {
      title: t("AUTHOR_NAME"),
      dataIndex: "author_name",
      key: "author_name",
      width: "120px",
      render: (author_name: any) => {
        return (
          <>
            <ShortText text={author_name} />
          </>
        );
      },
    },
    {
      title: t("NAME_PRODUCT"),
      dataIndex: "name_product",
      key: "name_product",
      width: "140px",
      render: (name_product: any) => {
        return (
          <>
            <ShortText text={name_product} />
          </>
        );
      },
    },
    {
      title: (
        <span>
          <span>{t("MORTGAGE_COL_INITIAL_ASSESS_PRICE")}</span>
          <br />
          <span>({t("IMPORTANCE")}%)</span>
        </span>
      ),
      dataIndex: "initial_value",
      key: "initial_value",
      width: "160px",
      render: (initial_value: any) => {
        return (
          <span>
            <span>
              <ShortNumber text={Number(initial_value[0])} />
            </span>
            <br />
            <span>
              (<ShortNumber text={Number(initial_value[1])} toFix={2} />
              %)
            </span>
          </span>
        );
      },
    },
    {
      title: (
        <span>
          <span>{t("MORTGAGE_COL_CURRENT_RATING_AMOUNT")}</span>
          <br />
          <span>({t("IMPORTANCE")}%)</span>
        </span>
      ),
      dataIndex: "current_value",
      key: "current_value",
      width: "160px",
      render: (current_value: any) => {
        return (
          <span>
            <span>
              <ShortNumber text={Number(current_value[0])} />
            </span>
            <br />
            <span>
              (<ShortNumber text={Number(current_value[1])} toFix={2} />
              %)
            </span>
          </span>
        );
      },
    },
    {
      title: t("MORTGAGE_COL_CURRENT_ALLOTMENT_AMOUNT"),
      dataIndex: "current_allotment",
      key: "current_allotment",
      width: "160px",
      render: (current_allotment: any) => {
        return (
          <>
            <ShortNumber text={Number(current_allotment)} />
          </>
        );
      },
    },
    {
      title: t("MORTGAGE_SELECT_PRESERVE_STATE"),
      dataIndex: "preserve_state",
      key: "preserve_state",
      width: "120px",
      render: (preserve_state: any) => {
        return (
          <>
            <ShortText
              text={
                storage_status.find((e) => e.value === preserve_state)?.label
              }
            />
          </>
        );
      },
    },
    {
      title: t("MORTGAGE_COL_MORTGAGE_STATUS"),
      dataIndex: "mortgage_status",
      key: "mortgage_status",
      width: "120px",
      render: (mortgage_status: any) => {
        return (
          <>
            <ShortText
              text={
                mortgage_status_translate.find(
                  (e: any) => e.value === mortgage_status
                )?.label
              }
            />
          </>
        );
      },
    },
  ];



  return type === "guaranteeList" ? (
    <>
      <div className={open ? "confirm show" : "confirm"}>
        <div className="confirm-content">
          <h4>{t("COLLATERAL_INFORMATION")}</h4>
          <div className="guarantee-list">
            <TableAntd
              // rowKey={(record: any) => record.id}
              columns={columns}
              dataSource={dataSource}
              pagination={{
                hideOnSinglePage: true,
                itemRender: itemRender,
                pageSize: 5,
                total: totalItem,
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    history.push(
                      `${UrlInternal.DETAIL_MORTGAGE}/${record?.id}`
                    );
                  }, // click row
                };
              }}
              rowKey={(record: any) => record.id}
            />
          </div>
        </div>
        <S.Close>
          <div onClick={() => handleConfirm(false)}>
            <CloseOutlined />
          </div>
        </S.Close>
      </div>
      <div className="overlay" onClick={() => handleConfirm(false)} />
    </>
  ) : (
    <>asd</>
  );
};

export default PopUp;
