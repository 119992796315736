import { mainAxios } from "libs/axios";

/**Faq Props type */
interface registerLoanListProps {
  page?: number;
  size?: number;
  querySearch?: string;
}

export const registerLoanListApi = (params: registerLoanListProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/register-loan/all-loan-profile`,
    params: params,
    notError: true,
    requiresToken: true,
  });
};

interface registerLoanDetailProps {
  loanId: number;
}

export const registerLoanDetailApi = (params: registerLoanDetailProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/register-loan/get-detail-loan`,
    params: params,
    notError: true,
    requiresToken: true,
  });
};

//update register Loan
// interface UpdateRegisterLoanProps {}

// export const updateRegisterLoanApi = (payload: UpdateRegisterLoanProps) => {
//   return mainAxios.request({
//     methodType: "GET",
//     url: `/register-loan/create-loan-profile`,
//     payload: payload,
//     notError: true,
//     requiresToken: true,
//   });
// };

//send register loan
interface sendRegisterLoanProps {
  type: string | null;
  email: string;
  title: string;
  bodyText: string;
  loanId: number;
}

export const sendRegisterLoanApi = (payload: sendRegisterLoanProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/register-loan/send-email`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};
