import { file } from "@babel/types";
import { Input, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from '@ant-design/icons';
import { Button, Space, Upload } from 'antd';
import * as S from "./style";

interface Props {
  id?: any;
}


function UploaD(props: Props) {
  const acceptedDatas = [];

  // const file: any = document.getElementById(props.id);
  // console.log(file)

  const beforeUpload = (file: any) => {
    if (file.size / 1024 / 1024 >= 5) {
      message.warning(
        `최대 5Mb 이하 파일만 업로드가 가능합니다. ${file.name} 파일을 다시 확인해주세요.`
      );
    } else {
      return false;
    }
  };

  return (
    <S.Container>
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture"
          maxCount={5}
          multiple
          accept="file"
          beforeUpload={beforeUpload}
          id={props.id}
          className="upload"
        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </Space>

    </S.Container>
  );
}
export default UploaD;
