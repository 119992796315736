import styled from "styled-components";

export const Container = styled.div`
  /**table */
  /* .ant-table-row {
    .ant-table-cell-row-hover {
      background-color: rgb(49 53 87 / 20%) !important;
    }
  } */
  .ant-table-wrapper {
    .ant-table-cell {
      font-family: "Montserrat-Regular";
      font-size: 14px;
      letter-spacing: 0.4px;
      text-align: center;
      cursor: default;
      /* color: #5d7160; */
    }
    .ant-table-header {
      .ant-table-cell {
        font-family: "Montserrat-SemiBold";
        font-size: 16px;
        font-weight: 600;
        &::before {
          content: none !important;
        }
      }
      .ant-table-thead > tr > th {
        border: none;
        /* background-color: #e6f7ff; */
        background-color: #5a5d79;
        color: #fff;
        padding: 15px 8px;
      }
    }
    .ant-table-body {
      cursor: pointer;
      .ant-table-tbody > tr > td {
        border-bottom: 0;
        padding: 8px 10px;
      }
    }
    .row-style-1 {
      background-color: #f5f5f575;
      .ant-table-cell-fix-left {
       background-color: #f5f5f5;
      }
    }
  }
  /** pagination */
  .ant-pagination {
    margin-top: 42px;
    align-items: center;
    gap: 0px;

    .ant-pagination-next,
    .ant-pagination-prev {
      svg {
        margin-top: 9px;
      }
    }

    .ant-pagination-item {
      border: none;
      a {
        font-size: 14px;
        color: #111;
        font-family: "Montserrat-Regular";
        margin-top: 0.8px;
        :hover {
          color: rgb(49 53 87);
        }
      }
    }
    .ant-pagination-item-active {
      border: solid 1px rgb(49 53 87);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: rgb(49 53 87);
      line-height: 31px;
      a {
        color: rgb(49 53 87);
        font-family: "Montserrat-Regular";
        margin-top: -0.2px;
        padding: 0;
      }
    }
    .ant-pagination-item-ellipsis {
      top: 30%;
    }
    .ant-pagination-options {
      display: none;
    }
  }
`;
