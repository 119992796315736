import React from "react";
import * as S from "./style";

interface Props {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

function ButtonRegister({ children, className, onClick }: Props) {
  return (
    <S.Container className={className} onClick={onClick}>
      {children}
    </S.Container>
  );
}

export default ButtonRegister;
