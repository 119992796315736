/**
 * @author [Peter]
 * @email [hoangvanlam9988@mail.com]
 * @create date 2022-02-19 15:25:35
 * @modify date 2022-02-19 15:25:35
 * @desc [description]
 */

import { UrlInternal } from "common/constants/endpoints";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useHistory } from "react-router-dom";
import { RootState } from "redux/reducer";
/** Local Data */

export function PublicRoute({
  component: TargetPage,
  ...rest
}: any): React.ReactElement {
  return <Route {...rest} render={(props: any) => <TargetPage {...props} />} />;
}

export function PrivateRoute({
  component: TargetPage,
  isAuthenticated,
  ...rest
}: any): React.ReactElement {
  const history = useHistory();
  // console.log(isAuthenticated, 'isAuthenticated');
  let acToken = useSelector((state: RootState) => state.userSlice.acToken);

  useEffect(() => {
    if (!acToken)
      history.push(UrlInternal.LOGIN, { fromURL: history.location.pathname });
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        acToken !== null ? (
          <TargetPage {...props} />
        ) : (
          <Redirect
            to={{
              pathname: UrlInternal.LOGIN,
              state: { fromURL: props?.location?.pathname },
            }}
          />
        )
      }
    />
  );
}
