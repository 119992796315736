import "antd/dist/antd.css";
import { UrlInternal } from "common/constants/endpoints";
/** Untils */
import GlobalStyles from "common/style/GlobalStyle";
import Normalize from "common/style/Normalize";
import defaultTheme from "common/style/themes/default";
import React, { lazy } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  // Route,
  Switch,
} from "react-router-dom";
import { RootState } from "redux/reducer";
/** Router Global */
import { PrivateRoute, PublicRoute } from "router";
import { ThemeProvider } from "styled-components";
import Template from "templates";

/** Page */
const Test = lazy(() => import("pages/Test"));
const DashBoard = lazy(() => import("pages/DashBoard"));
const Login = lazy(() => import("pages/Login"));
const Faq = lazy(() => import("pages/Faq"));
const RegisterFaq = lazy(() => import("pages/Faq/RegisterFaq"));

const DetailFaq = lazy(() => import("pages/Faq/DetailFaq"));
const RegisterLoan = lazy(() => import("pages/RegisterLoan"));
const RegisterLoanDetail = lazy(
  () => import("pages/RegisterLoan/RegisterLoanDetail")
);
const ManageBorrower = lazy(() => import("pages/ManageBorrower"));
const RegisterBorrower = lazy(
  () => import("pages/ManageBorrower/RegisterBorrower")
);

const LoanManagement = lazy(() => import("pages/LoanManagement"));
const CreateContract = lazy(
  () => import("pages/LoanManagement/CreateContract")
);
const DetailContract = lazy(
  () => import("pages/LoanManagement/DetailContract")
);

const RegisterCompany = lazy(
  () => import("pages/ManageBorrower/RegisterCompany")
);
const MortGage = lazy(() => import("pages/MortGage"));
const RegisterMortGage = lazy(() => import("pages/MortGage/RegisterMortGage"));
const DetailMortGage = lazy(() => import("pages/MortGage/DetailMortGage"));
const CopyMortGage = lazy(() => import("pages/MortGage/CopyMortGage"));

const BorrowerDetail = lazy(
  () => import("pages/ManageBorrower/BorrowerDetail")
);
const CompanyDetail = lazy(() => import("pages/ManageBorrower/CompanyDetail"));
const Calendar = lazy(() => import("pages/Calendar"));

const App: React.FC = () => {
  let acToken = useSelector((state: RootState) => state.userSlice.acToken);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Normalize />
      <GlobalStyles />
      <Router>
        <Switch>
          <Redirect exact from={UrlInternal.HOME} to={UrlInternal.DASHBOARD} />

          <PublicRoute path={UrlInternal.LOGIN} component={Login} />
          <Template>
            <PrivateRoute
              exact
              path={UrlInternal.DASHBOARD}
              component={DashBoard}
            />

            <PrivateRoute exact path={UrlInternal.FAQ} component={Faq} />

            <PrivateRoute
              exact
              path={UrlInternal.FAQ_DETAIL + "/:id"}
              component={DetailFaq}
            />

            <PrivateRoute
              exact
              path={UrlInternal.FAQ_REGISTER}
              component={RegisterFaq}
            />

            <PrivateRoute
              exact
              path={UrlInternal.REGISTER_LOAN}
              component={RegisterLoan}
            />

            <PrivateRoute
              exact
              path={UrlInternal.REGISTER_LOAN_DETAIL + "/:id"}
              component={RegisterLoanDetail}
            />

            <PrivateRoute
              exact
              path={UrlInternal.MANAGE_BORROWER}
              component={ManageBorrower}
            />
            <PrivateRoute
              exact
              path={UrlInternal.MORTGAGE}
              component={MortGage}
            />
            <PrivateRoute
              exact
              path={UrlInternal.REGISTER_BORROWER}
              component={RegisterBorrower}
            />
            <PrivateRoute
              exact
              path={UrlInternal.REGISTER_COMPANY}
              component={RegisterCompany}
            />

            <PrivateRoute
              exact
              path={UrlInternal.REGISTER_MORTGAGE}
              component={RegisterMortGage}
            />

            <PrivateRoute
              exact
              path={UrlInternal.COPY_MORTGAGE}
              component={CopyMortGage}
            />

            <PrivateRoute
              exact
              path={UrlInternal.DETAIL_MORTGAGE + "/:id"}
              component={DetailMortGage}
            />
            <PrivateRoute
              exact
              path={UrlInternal.DETAIL_BORROWER + "/:id"}
              component={BorrowerDetail}
            />

            <PrivateRoute
              exact
              path={UrlInternal.DETAIL_COMPANY + "/:id"}
              component={CompanyDetail}
            />
            <PrivateRoute
              exact
              path={UrlInternal.LOAN}
              component={LoanManagement}
            />
            <PrivateRoute
              exact
              path={UrlInternal.CREATE_CONTRACT}
              component={CreateContract}
            />
            <PrivateRoute
              exact
              path={UrlInternal.DETAIL_CONTRACT + "/:id"}
              component={DetailContract}
            />
            <PrivateRoute
              exact
              path={UrlInternal.CALENDAR}
              component={Calendar}
            />
          </Template>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
