import { mainAxios } from "libs/axios";

export type workImage = {
  name: string;
  type: string;
  image_url: string;
};

interface postCompanyInformation {
  type?: string; 
  level?: string; 
  name?: string; 
  email?: string;
  phoneNumber?: string; 
  zipCode?: string;
  address?: string; 
  address2?: string; 
  businessRegistrationNumber?: string;  
  businessRegistration: workImage[]; 
  stamp?: workImage[]; 
  workCertificate?: workImage[]; 
  powerAttorney?: workImage[]; 
  accountCompanyCopy?: workImage[]; 
  directorName?: string; 
  directorIdentityCardNumber?: string; 
  directorPhoneNumber?: string;
  zipCode1?: string; 
  directorAddress?: string; 
  directorAddress2?: string;
  authorizerName?: string; 
  authorizerIdentityCardNumber?: string; 
  authorizerPhoneNumber?: string;
  zipCode2?: string; 
  authorizerAddress?: string;  
  authorizerAddress2?: string; 
}

export const postCompanyRegisterAPI = (payload: postCompanyInformation) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/borrower/create-borrower`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};