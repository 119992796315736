import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  .ant-tabs-top > .ant-tabs-nav::before {
        display: none !important;
  }
  .ant-tabs {
    .item {
      font-family: "Montserrat-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      padding: 0 4px;

      display: flex;
      align-items: center;
      gap: 8px;
      .value {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 22px;
        padding: 0 8px;
        border-radius: 12px;
        font-size: 13px;
        line-height: 18px;
        background: #f5f5f5;
      }
     
    }
    .ant-tabs-tab {
      :hover {
        .ant-tabs-tab-btn {
          color: #1890ff;         
          font-weight: 500;
        }
      }
    }
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #1890ff;     
      }
      .item {
        gap: 8px;
        .value {
          background: #1890ff;
        }
      }
    }
    .ant-tabs-ink-bar {
      background-color: #1890ff;   }
  }`
;

export const ItemTab = styled.div`
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding: 0 4px;

  display: flex;
  align-items: center;
  gap: 8px;
  `
;