import styled from "styled-components";

export const Container = styled.div`
  font-size: 14px;
  padding: 4px 40px;
  display: inline-block;
  font-weight: 600;
  background-color: #fff;
  border: 1px solid rgb(49 53 87);
  color: rgb(49 53 87 / 80%);
  border-radius: 5px;
  cursor: pointer;
`;
