import React from 'react';
import { Select } from 'antd';
import * as S from "./style"

interface Props {
  type?: string;
  mode?: any;
  placeholder?: string;
  onChange?: (event: any) => void;
  style?: any;
  option?: any;
  onSearch?: (event: any) => void;
  filterOption?: any;
  defaultValue?: any;
  disabled?: boolean;
}

function DropDown({disabled, defaultValue,  mode, placeholder, onChange, style, option, type, onSearch, filterOption }: Props) {
  return (type === "normal") ? (
    <S.Container>
      <Select
        mode={mode}
        size="large"
        placeholder={placeholder}
        onChange={onChange}
        style={style}
        options={option}
        defaultValue={defaultValue}
        disabled={disabled}
      />
    </S.Container>
  ) : (type === "search") ?  (
    <S.Container>
      <Select
        size="large"
        showSearch
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearch}
        filterOption={filterOption}
        options={option}
        defaultValue={defaultValue}
        disabled={disabled}
    
      />
    </S.Container>
  ) 
  // : (type === "large") ? (
  //   <S.Container>
  //     <Select
  //       size="large"
  //       options={option}

  //     />
  //   </S.Container>
  // ) 
  : (
    <></>
  )

}

export default DropDown;