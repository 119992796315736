import React from "react"; //lazy
import * as S from "./style";
import { Popover } from "antd";

interface Props {
  text?: number;
  toFix?: number;
}

function ShortNumber({ text, toFix = 0 }: Props): React.ReactElement {
  const handleText = (text: any) => {
    let newText: any = 0;
    if (text) {
      if (toFix) {
        const rate: number = 10 ** toFix;
        newText = +(text * rate).toFixed(0) / rate;
      } else {
        newText = text.toFixed(toFix);
      }
    }
    return newText;
  };

  return String(text).length === 0 || text === null ? (
    <S.Container>
      <span>_</span>
    </S.Container>
  ) : (
    <S.Container>
      {/* <Popover
        content={handleText(text!)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
      > */}
        {handleText(text!)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
      {/* </Popover> */}
    </S.Container>
  );
}

export default ShortNumber;
