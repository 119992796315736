import React from 'react';
import moment from "moment-timezone";

interface Props {
  timeData?: any;
};

function TranslateTime(props: Props) {
  const data = props.timeData;
  return (
    <>
      {moment(data).format("YYYY-MM-DD HH:mm:ss")}
    </>
  )
}

export default TranslateTime;