import { mainAxios } from "libs/axios";

export const calendarAPI = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/calendar/contract-to-be-paid`,
    requiresToken: true,
  });
};

export const holidayAPI = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/calendar/call-3party`,
    requiresToken: true,
  });
};