import axios from "axios";
import { BASE_URL } from "libs/axios";
import { store } from "redux/store";

//export excel mortgage
interface mortgageProps {
  reason: string;
  userId: number;
  userDownload: string;
  storageStatus: string;
  mortgageStatus: string;
  querySearch: string;
}

export async function downloadExcelApi(payload: mortgageProps) {
  const acToken = store.getState()?.userSlice?.acToken;

  return await axios({
    url: `/mortgage_management/export`,
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${acToken}`,
      "Content-Type": "application/json",
    },
    method: "POST",
    responseType: "blob",
    data: payload,
  }).then((response) => {
    return response;
  });
}

//export excel borrower
interface borrowerProps {
  type: string;
  userId: number;
  userName: string;
  reason: string;
  querySearch: string;
}

export async function downloadExcelBorrowerApi(payload: borrowerProps) {
  const acToken = store.getState()?.userSlice?.acToken;

  return await axios({
    url: `/borrower/export`,
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${acToken}`,
      "Content-Type": "application/json",
    },
    method: "POST",
    responseType: "blob",
    data: payload,
  }).then((response) => {
    return response;
  });
}

//export excel debt repayment details page detail loan
interface debtRepaymentDetailsProps {
  loanId: number;
  reason: string;
  userId: number;
  userDownload: string;
}

export async function downloadExcelDebtRepaymentApi(
  payload: debtRepaymentDetailsProps
) {
  const acToken = store.getState()?.userSlice?.acToken;

  return await axios({
    url: `/repayment/export-excel`,
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${acToken}`,
      "Content-Type": "application/json",
    },
    method: "POST",
    responseType: "blob",
    data: payload,
  }).then((response) => {
    return response;
  });
}

interface LoanManagementProps {
  reason: string;
  userId: number;
  userName: string;
  querySearch: string;
  contractStartDate: string;
}

export async function downloadExcelLoanManagementApi(
  payload: LoanManagementProps
) {
  const acToken = store.getState()?.userSlice?.acToken;

  return await axios({
    url: `/loan-manager/export-loan-manager`,
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${acToken}`,
      "Content-Type": "application/json",
    },
    method: "POST",
    responseType: "blob",
    data: payload,
  }).then((response) => {
    return response;
  });
}