
function formatPhoneNumber(value: any) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 3) { return phoneNumber; }
  else if (phoneNumberLength < 6) {
    return `${phoneNumber.slice(0, 2)}-${phoneNumber.slice(2)}`
  } else if (phoneNumberLength < 10) {
    return `${phoneNumber.slice(0, 2)}-${phoneNumber.slice(2, 5)}-${phoneNumber.slice(5)}`
  } else if (phoneNumberLength === 10) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`
  } else if (phoneNumberLength > 10) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7, 11)}`
  }
}


export default function phoneNumberFormatter(id: any) {
  const inputField = document.getElementById(id) as HTMLInputElement | null;
  const formattedInputValue = formatPhoneNumber(inputField!.value);
  inputField!.value = formattedInputValue;
}

