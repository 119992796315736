import React from "react";
import * as S from "./style";
import { Input } from "antd";

interface Props {
 data?: number;
 phoneNumber?: boolean;
 onChange?: any;
}

function InputFormat({phoneNumber, data, onChange}: Props) {

  return phoneNumber ? (
    <S.Container>
      <Input
      onChange={(e: any) => {onChange(e)}}
      />
    </S.Container>
  ) : (
    <S.Container>
      <Input
      
      />
    </S.Container>
  )
}

export default InputFormat;