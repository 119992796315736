
  function formatIDNumber(value: any) {
    if (!value) return value;
    const idNumber = value.replace(/[^\d]/g, '');
    const idNumberLength = idNumber.length;
    if (idNumberLength < 7) return idNumber;
    else {
      return `${idNumber.slice(0, 6)}-${idNumber.slice(6, 13)}`;
    }
  }

  export default function IDFormatter(id: any) {
    const inputField = document.getElementById(id) as HTMLInputElement | null;
    const formattedInputValue = formatIDNumber(inputField!.value);
    inputField!.value = formattedInputValue;
  }

  