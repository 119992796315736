import styled, { css } from "styled-components";
import { Layout } from "antd";
const { Header, Content, Sider } = Layout;

export const Container = styled.div`
  .site-layout {
    background-color: #f8f9fa;
    background-image: linear-gradient(180deg, #f8f9fa, #eaeef7);
    .ant-input-suffix {
      cursor: pointer;
    }
    .btn-trigger {
      cursor: pointer;
      > span {
        font-size: 22px;
      }
    }
  }

  .ant-layout-sider-trigger {
    /* background-color: #4a79fd; */
    display: none;
  }

  .ant-layout-header {
    background-color: transparent;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 35px;

    .ant-input-affix-wrapper {
      max-width: 450px;
      border-radius: 100px;
      padding: 4px 25px;

      .ant-input {
        min-height: 35px;
      }

      .ant-input-suffix {
        svg {
          width: 20px;
          height: auto;
        }
      }
    }
    .ant-row {
      .hr-rotate {
        margin: 0 25px 0 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .item-hr {
          width: 2px;
          background-color: #d9d9d9;
          height: 90%;
        }
      }

      .avatar {
        width: 50px;
        height: auto;
        object-fit: contain;
      }

      .box-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        .name-admin {
          font-weight: 700;
          line-height: 22px;
          font-size: 30px;
          font-size: 16px;
        }
        .position-user {
          line-height: 18px;
          font-size: 13px;
          font-weight: 600;
        }
      }

      .ti-home {
        font-size: 20px;
        cursor: pointer;
      }

      .container-bell {
        margin-left: 20px;
        .box-bell {
          position: relative;
          cursor: pointer;

          .ant-badge-count {
            top: 8px;
            right: 8px;
          }

          .count {
            position: absolute;
            right: 0;
            top: 0px;
            border-radius: 50%;
            padding: 4px;
            background-color: red;
            color: #fff;
            font-weight: 600;
            font-size: 11px;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .box-notification {
            position: absolute;
            top: 65px;
            right: -30px;
            border: 1px solid #d9d9d9;
            max-height: 500px;
            overflow-y: auto;
            z-index: 2;
            width: 350px;
            background-color: #fff;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            border-radius: 5px;
            &::-webkit-scrollbar {
              -webkit-appearance: none;
              width: 5px;
              display: block;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 4px;
              background-color: #313557;
              -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            }

            > div {
              line-height: 22px;
              font-size: 13px;
            }

            .dots {
              width: 10px;
              height: 10px;
              background-color: red;
              border-radius: 50%;
            }

            &__item {
              padding: 10px 15px;
              display: flex;
              align-items: center;
              gap: 20px;
              border-bottom: 1px solid #d9d9d9;
              &:last-child {
                border-bottom: none;
              }
              &:hover {
                color: #006ce7;
              }
            }
          }
        }
      }

      .center-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
`;

export const SliderBox = styled(Sider)<{ active?: any }>`
  background-color: #313557;
  .ant-layout-sider-children {
    position: fixed;
    .box-logo {
      background-color: #313557;
      text-align: center;

      img {
        object-fit: contain;
        cursor: pointer;
      }
    }
    ${({ active }) =>
      active
        ? css`
            width: 80px;
            transition: all 0.2s;
            .box-logo {
              padding: 16px 0;
              margin-bottom: 30px;
              img {
                width: calc(100% - 20px);
              }
            }
          `
        : css`
            width: 200px;
            transition: all 0.4s;
            .box-logo {
              padding: 25px 0;
              margin-bottom: 55px;
              img {
                width: 170px;
              }
            }
          `}
  }
  .ant-menu {
    background-color: #313557;
    color: #73768d;
    font-weight: 600;
  }
  .ant-menu-vertical {
    border-right: none;
  }
  .ant-menu-inline {
    border-right: none;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba(0, 0, 0, 0.22);
  }
  .ant-menu-item-selected {
    color: #fff;
  }
  .ant-menu-item::after {
    content: none;
  }
  .ant-menu-item-active {
    background-color: rgba(0, 0, 0, 0.22);
    color: #fff;
  }
  .ant-menu-item {
    margin: 0;
    height: 55px;
    line-height: 55px;
    ${({ active }) =>
      active
        ? css`
            padding: 0 calc(50% - 12px) !important;
            svg {
              zoom: 1.5;
              transition: 0.3s;
            }
          `
        : css``}
  }
  .ant-menu-submenu-title {
    margin: 0;
    height: 55px;
    line-height: 55px;
  }
  .ant-menu-submenu-active {
    background-color: rgba(0, 0, 0, 0.22);
    color: #fff;
    .ant-menu-submenu-title {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
    .ant-menu-submenu-arrow {
      color: #fff;
    }
  }

  .hr-sider {
    width: calc(100% - 40px);
    border: 1px solid #73768d;
    margin: 40px auto;
    ${({ active }) =>
      active
        ? css`
            width: calc(100% - 20px);
            margin: 20px auto;
          `
        : css``}
  }

  .box-logout {
    display: flex;
    align-items: center;
    padding: 0 25px;
    gap: 10px;
    cursor: pointer;
    margin: 0;
    height: 55px;
    line-height: 55px;
    color: #73768d;
    &:hover {
      color: #1890ff;
      background-color: rgba(0, 0, 0, 0.22);
    }
    svg {
      transition: 0.3s;

      ${({ active }) =>
        active
          ? css`
              zoom: 1.5;
            `
          : css``}
    }
  }
`;
