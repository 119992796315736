import styled from "styled-components";

export const Close = styled.div`
  font-size: 20px;
  position: absolute;
  top: 7px;
  right: 24px;

  :hover {
    color: red;
    font-size: 22px;
    top: 5.5px;
    right: 23px;
    transition: 0.2s;
  }
`;

export const Container = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--body-font) !important;
    --primary: #0e2431;
    --secondary: #4070f4;
    --secondary-dark: #3665eb;
    --gray: #666;
    --body-font: "Montserrat-Regular", sans-serif;
    --table-head: rgb(49 53 87 / 80%);
  }

  button {
    border: 0;
    outline: 0;
  }

  body {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary);
  }

  .confirm {
    width: fit-content;
    height: auto;
    background: #fff;
    transition: 0.6s;

    z-index: 100;
    position: fixed;
    top: -900px;
    /* left: calc(33% - 180px); */
    left: calc(50% - 500px);
    /* left: 20%; */
    border-radius: 11px;

    padding: 10px;
  }

  .confirm.show {
    top: calc(25% - 140px);
  }

  .confirm-content {
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--primary);
    padding: 1.5rem;

    .ant-table-tbody > tr > td {
      word-break: break-word;
    }
  }

  .confirm-content h4 {
    font-size: 22px;
    font-weight: 800;
    position: relative;
    padding: 0 0 20px 0;
  }

  .confirm-content h4::after {
    content: "";
    width: 100%;
    height: 2px;
    background: var(--secondary);
    position: absolute;
    bottom: -7px;
    left: 0;
  }

  .confirm-content h2 {
    font-size: 2rem;
    font-weight: 300;
    margin-top: 3rem;
  }

  .confirm-content p {
    font-size: 16px;
    color: var(--gray);
    text-align: center;
    margin-top: 3.5rem;
  }

  .confirm-btns {
    height: 30%;
    display: flex;
    justify-content: space-evenly;
  }

  .confirm-btns button {
    width: 120px;
    height: 50px;
    background: var(--secondary);
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    padding: 1rem 0;
    cursor: pointer;
    border-radius: 11px;
  }

  .typeII button {
    width: 120px;
  }

  .confirm-btns button:hover {
    background: var(--secondary-dark);
  }

  .overlay {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .confirm.show + .overlay {
    display: block;
  }

  .button {
    background: var(--secondary);
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    padding: 1rem 4rem;
  }

  .guarantee-list {
    /* margin: 0 0 10px 0; */
    .ant-table-thead > tr > th {
      background-color: var(--table-head);
      color: #fff;
      font-size: 14px;
      border-right: 1px solid #00000050;
      :nth-child(1) {
        /* border-left: 1px solid #00000050; */
      }
    }
    .ant-table-tbody > tr {
      &.row-style-1 {
        background-color: #00000009;
      }
      :nth-last-child(1) {
        td {
          border-bottom: 1px solid #00000050;
        }
        &.ant-table-placeholder {
          td {
            border: none;
          }
        }
      }
      td {
        cursor: pointer;
        border-right: 1px solid #00000030;
        :nth-child(1) {
          border-left: 1px solid #00000030;
        }
      }
    }

    /* .ant-table-wrapper .ant-table-header .ant-table-thead > tr > th {
      font-size: 14px;
      border-right: 1px solid #00000050;
      :nth-child(1) {
        border-left: 1px solid #00000050;
      }
    }
    .ant-table-wrapper .ant-table-body .ant-table-tbody > tr {
      &.row-style-1 {
        background-color: #00000009;
      }
      :nth-last-child(1) {
        td {
          border-bottom: 1px solid #00000050;
        }
        &.ant-table-placeholder {
          td {
            border: none;
          }
        }
      }
      td {
        font-size: 13px;
        :nth-child(11) {
          white-space: nowrap;
        }
        border-right: 1px solid #00000030;
        :nth-child(1) {
          border-left: 1px solid #00000030;
        }
      }
    } */
  }

  @media screen and (max-width: 450px) {
    .confirm {
      width: 300px;
      left: calc(50% - 150px);
    }
  }
`;

export const PopUp = styled.div`
  max-width: 350px;
  width: 100%;
  min-height: 150px;
  background: #1f0740;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  border-radius: 11px;
  box-shadow: 3px 3px #545454;
  color: #fff;
`;

export const Title = styled.div`
  font-size: 22px;
`;

export const Info = styled.div``;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  color: #090a2c;
`;

export const Button = styled.button`
  border-radius: 8px;
  color: #fff;
  background-color: #517cf5;
  height: 35px;
  padding: 0px 15px;

  &.disabled {
    background-color: #ddd;
    color: #000;

    :hover {
      background-color: #ddd;
      color: #000;
    }
  }

  :hover {
    background-color: #3665eb;
    color: #fff;
  }
`;
