import { mainAxios } from "libs/axios";

/**mortgage list Props type */
interface mortgageListProps {
  page?: number;
  size?: number;
  querySearch?: string;
  storageStatus?: string;
  mortgageStatus?: string;
  orderByKey?: string;
  orderByValue?: string;
}

export const mortgageListApi = (params: mortgageListProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/mortgage_management/all-mortgage-management`,
    params: params,
    notError: true,
    requiresToken: true,
  });
};

export type workImage = {
  name: string;
  type: string;
  image_url: string;
};

/**Create mortgage Props type */
interface createMortgageListProps {
  mortgageManagementId: number | null;
  borrowerName: string;
  mortgageNumber: string;
  loanManagerId: number;
  borrowerId: number;
  contractNumber: number;
  mortgageClassification: string;
  authorName: string;
  workName: string;
  workHeight: number;
  workWidth: number;
  workDepth: number;
  category: string;
  makeYear: number;
  workMaterial: string;
  workOrigin: string;
  workImage: workImage[];
  exhibitionHistory: string;
  savePlace: string;
  certificate: string;
  certificateDate: string;
  certificateResults: string;
  storageLocation: string;
  storageStatus: string;
  mortgageStatus: string;
  initialAmount: number;
  currentAmount: number;
  initialAllocateAmount: number;
  currentAllocateAmount: number;
  initialLtv: number;
  currentLtv: number;
  workStatus: string;
  note: string;
  type: string | null;
  verified: boolean;
  countCopy: number;
  storehouseInDate: string;
  storehouseOutDate: string;
}

export const createMortgageListApi = (payload: createMortgageListProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/mortgage_management/create-mortgage-management`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};

//update mortgage
export const updateMortgageListApi = (payload: createMortgageListProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/mortgage_management/update-mortgage-management`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};

//** Detail mortgage */

interface detailMortgageProps {
  mortgageManagementId: number;
}

export const detailMortgageApi = (params: detailMortgageProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/mortgage_management/one-mortgage-management`,
    params: params,
    notError: true,
    requiresToken: true,
  });
};

//get list name member mortgage

interface listNameMemberMortgageProps {
  querySearch?: string;
}

export const listNameMemberMortgage = (params: listNameMemberMortgageProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/loan-manager/get-borrower`,
    params: params,
    notError: true,
    requiresToken: true,
  });
};

//get list number hd

interface getListNumberHD {
  querySearch: string;
}

export const listNumberHDApi = (params: getListNumberHD) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/loan-manager/get-loan-by-borrower`,
    params: params,
    notError: true,
    requiresToken: true,
  });
};

//get ID mortgage

export const getIDMortgageApi = () => {
  return mainAxios.request({
    methodType: "POST",
    url: `/mortgage_management/generate-mortgage-number`,
    notError: true,
    requiresToken: true,
  });
};

//get list author
export const getListAuthor = () => {
  return mainAxios.request({
    methodType: "POST",
    url: `/register-loan/authors`,
    notError: true,
    requiresToken: true,
  });
};

interface calculatorAssessPriceApiProps {
  loanManagerId?: number;
  initialAmount: number;
  currentAmount: number;
  mortgageManagementId?: number;
}

export const calculatorAssessPriceApi = (
  payload: calculatorAssessPriceApiProps
) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/mortgage_management/calculate-before-create`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};

interface postNewInOutDateProps {
  mortgageManagementId?: number;
  storehouseInDate: string;
  storehouseOutDate: string;
  note?: string;
}

export const postNewInOutDateApi = (
  payload: postNewInOutDateProps
) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/mortgage_management/create-mortgage-storehouse-history`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};

interface getHistoryInOutDateParams {
  mortgageManagementId: string;
  page: string;
  size: string;
}

export const historyInOutDateParamsApi = (params: getHistoryInOutDateParams) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/mortgage_management/all-mortgage-storehouse-history`,
    params: params,
    notError: true,
    requiresToken: true,
  });
};
