import React from "react"; //lazy
import * as S from "./style";
import { Popover } from "antd";
import { AntAnchor } from "antd/lib/anchor/Anchor";

interface Props {
  text?: any;
  number?: any;
}

function ShortContent({ text, number }: Props): React.ReactElement {
  const handleText = (text: any) => {
    if (text?.length > 5 && text !== null) {
      text = `${text?.substring(0, 5)}...`;
      return text;
    }
    return text;
  };

  return text === null ? (
    <S.Container>
      <span>_</span>
    </S.Container>
  ) : number === true ? (
    <S.Container>
      <Popover
        content={text.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
      >
        {handleText(text)}
      </Popover>
    </S.Container>
  ) : (
    <S.Container>
      <Popover content={text}>{handleText(text)}</Popover>
    </S.Container>
  );
}

export default ShortContent;
