import React, { useEffect, useState } from "react";
import {
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';

import * as S from "./style";

interface Props {
  addStyle?: any;
  onSort: (e: any, column: any) => void,
  column?: any;
  id?: any;
}

function Sorter(props: Props) {
  const { addStyle, onSort, column, id } = props;

  const [asc, setAsc] = useState(false)
  const [desc, setDesc] = useState(false)
  const [neutral, setNeutral] = useState(true)

  const handleSort = (e: any) => {
    if (e === "asc" && asc === false && desc === false) {
      setAsc(true);
      setNeutral(false);
      setDesc(false)
    } else if (e === "asc" && asc === true && desc === false) {
      setAsc(false)
      setNeutral(true)
      setDesc(false)
    } else if (e === "desc" && desc === false && asc === false) {
      setDesc(true)
      setNeutral(false)
      setAsc(false)
    } else if (e === "desc" && desc === true && asc === false) {
      setDesc(false)
      setNeutral(true)
      setAsc(false)
    } else if (e === "asc" && asc === false && desc === true) {
      setAsc(true)
      setDesc(false)
      setNeutral(false)
    } else if (e === "desc" && desc === false && asc === true) {
      setDesc(true)
      setAsc(false)
      setNeutral(false)
    }
  }

  useEffect(() => {
    let sortState = ""
    if (asc === true) {
      sortState = "asc"
      onSort(sortState, column)
    } else if (desc === true) {
      sortState = "desc"
      onSort(sortState, column)
    } else if (neutral === true) {
      sortState = ""
      onSort(sortState, "")
    }
  }, [asc, desc, neutral])

  return (
    <S.Container
      style={{ ...addStyle }}
    >
      <CaretUpOutlined
        style={(desc && id === column) ? { color: "black", fontSize: 10 } : { fontSize: 10 }}
        onClick={() => (handleSort("desc"))}
      />
      <CaretDownOutlined
        style={(asc && id === column) ? { color: "black", fontSize: 10 } : { fontSize: 10 }}
        onClick={() => (handleSort("asc"))}
      />
    </S.Container>
  )
}

export default Sorter;
