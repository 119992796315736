import { mainAxios } from "libs/axios";

/**Faq Props type */
interface faqProps {
  page?: number;
  size?: number;
  querySearch?: string;
}

export const faqApi = (params: faqProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/faq/all-faq`,
    params: params,
    notError: true,
  });
};

/**Create Faq Props type */
interface createFaqProps {
  title: string;
  description: string;
  checkActive: boolean;
}

export const createFaqApi = (payload: createFaqProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/faq/create-faq`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};

/**Update Faq Props type */
interface updateFaqProps {
  id: number;
  title: string;
  description: string;
  checkActive: boolean;
}

export const updateFaqApi = (payload: updateFaqProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/faq/edit-faq`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};

interface deleteFaqProps {
  faqId: number;
}

export const deleteFaqApi = (payload: deleteFaqProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/faq/delete-faq`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};

/**Faq detail Props type */
interface faqDetailProps {
  id: number;
}

export const faqDetailApi = (params: faqDetailProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/faq/get-detail`,
    params: params,
    notError: true,
    requiresToken: true,
  });
};
