import { mainAxios } from "libs/axios";

export const uploadApi = (formData: any) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/upload-files`,
    payload: formData,
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  });
};
