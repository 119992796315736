import { visitNotificationApi } from "apis";
import { CONTRACT_STATUS } from "common/constants/dataSelectLoan";
import { UrlInternal } from "common/constants/endpoints";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface Props {
  data: any[];
  callBackDataNotify: () => void;
}

function ModalNotify({ data, callBackDataNotify }: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const handleVisitNotify = async (id: number) => {
    const res: any = await visitNotificationApi({
      notifyId: id,
    });
    if (res) {
      callBackDataNotify();
    }
  };

  const changeTextContractStatus = (value: string) => {
    switch (value) {
      case CONTRACT_STATUS.REFUND_IN_PROGRESS:
        return t("REDEMPTION_IN_PROGRESS");
      case CONTRACT_STATUS.PARTIAL_REFUND:
        return t("PARTIAL_REPAYMENT");
      case CONTRACT_STATUS.LATE_PAYING:
        return t("OVERDUE");
      case CONTRACT_STATUS.UNUSUAL_LOSS_OF_INTERESTS:
        return t("LOSS_OF_PROFIT_BY_DEADLINE");
      case CONTRACT_STATUS.ON_SALE:
        return t("SELLING");
      case CONTRACT_STATUS.RESALE_COMPLETE:
        return t("SELL_COMPLETED");
      case CONTRACT_STATUS.REFUND_COMPLETE:
        return t("REDEMPTION_COMPLETED");

      default:
        return "";
    }
  };

  return (
    <>
      {data?.map((item, index) => (
        <div
          className="box-notification__item"
          key={index}
          onClick={() => {
            if (!item?.is_visited) {
              handleVisitNotify(item?.id);
            }
            history.push(
              `${UrlInternal.DETAIL_CONTRACT}/${item?.loan_manager_id}`
            );
          }}
        >
          <div>{!item?.is_visited && <div className="dots" />}</div>
          <div>
            <div>{moment(item?.create_date).format("YYYY-MM-DD")}</div>
            <div>
              {changeTextContractStatus(item?.contract_status)} [
              {item?.contract_number}]
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default ModalNotify;
