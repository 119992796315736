import React, { forwardRef } from "react";
import { Input } from "antd";
import NumberFormat from "react-number-format";

import * as S from "./style";

interface Props extends React.InputHTMLAttributes<any> {
  btntype?:
    | "BUTTON"
    | "LINE"
    | "BOTTOMLINE"
    | "SEARCH"
    | "SEARCH_ICON"
    | "BUTTON_LABEL"
    | "MONEY_LABEL"
    | "HAVE_MASK";
  type?: any;
  className?: string;
  id?: any;
  text?: string;
  searchText?: string;
  searchIcon?: any;
  placeholder?: string;
  onChange?: any;
  value?: string;
  unittext?: string;
  ref?: any;
  onKeyDown?: any;
  disabled?: boolean;
  onClick?: any;
  suffixText?: string;
  isTopLabel?: boolean;
  onSuffixEvent?: () => void;
  isDisabledSuffix?: boolean;
  onKeyPress?: any;
  pureNumber?: boolean;
  maxLength?: number;
  mask?: string;
}

// const InputMaskLabel = ({
//   type,
//   text,
//   className,
//   onChange,
//   value,
//   placeholder,
//   unittext,
//   ref,
//   isTopLabel,
//   mask,
// }: Props) => {
//   return (
//     <S.Container className={className}>
//       <NumberFormat
//         className="field-input input--button__money_label"
//         id="inputid"
//         name="inputName"
//         onChange={onChange}
//         value={value}
//         placeholder={placeholder ?? " "}
//         type={type}
//         ref={ref}
//         autoComplete="new-password"
//         format={mask ?? "###-###-####"}
//         onKeyDown={(evt: any) =>
//           (evt.key === "e" ||
//             evt.key === "-" ||
//             evt.key === "+" ||
//             evt.key === ",") &&
//           evt.preventDefault()
//         }
//       />
//       <S.TextLine
//         className={`field-placeholder ${isTopLabel ? "show-text" : ""}`}
//         htmlFor="inputName"
//       >
//         {text}
//       </S.TextLine>
//       <S.UnitText>{unittext}</S.UnitText>
//     </S.Container>
//   );
// };

// const InputMoneyLabel = ({
//   type,
//   text,
//   className,
//   onChange,
//   value,
//   placeholder,
//   unittext,
//   ref,
//   isTopLabel,
// }: Props) => {
//   return (
//     <S.Container className={className}>
//       <NumberFormat
//         className="field-input input--button__money_label"
//         id="inputid"
//         name="inputName"
//         onChange={onChange}
//         value={value}
//         placeholder={placeholder ?? " "}
//         type={type}
//         ref={ref}
//         autoComplete="new-password"
//         thousandSeparator={true}
//         onKeyDown={(evt: any) =>
//           (evt.key === "e" ||
//             evt.key === "-" ||
//             evt.key === "+" ||
//             evt.key === ",") &&
//           evt.preventDefault()
//         }
//       />
//       <S.TextLine
//         className={`field-placeholder ${isTopLabel ? "show-text" : ""}`}
//         htmlFor="inputName"
//       >
//         {text}
//       </S.TextLine>
//       <S.UnitText>{unittext}</S.UnitText>
//     </S.Container>
//   );
// };

const InputButtonLabel = ({
  type,
  text,
  className,
  onChange,
  value,
  placeholder,
  ref,
  suffixText,
  isTopLabel,
  onSuffixEvent,
  isDisabledSuffix = false,
  disabled,
}: Props) => {
  return (
    <S.Container className={className}>
      <Input
        className="field-input input--button__label"
        id="inputid"
        name="inputName"
        onChange={onChange}
        value={value}
        placeholder={placeholder ?? " "}
        type={type}
        ref={ref}
        autoComplete="new-password"
        disabled={disabled}
      />
      <S.TextLine
        className={`field-placeholder ${isTopLabel ? "show-text" : ""}`}
        htmlFor="inputName"
      >
        {text}
      </S.TextLine>
      <S.SuffixBtn
        onClick={onSuffixEvent}
        disabled={isDisabledSuffix}
        type="button"
      >
        {suffixText}
      </S.SuffixBtn>
    </S.Container>
  );
};

const InputLine = ({
  id,
  type,
  text,
  className,
  onChange,
  value,
  placeholder,
  unittext,
  ref,
  isTopLabel,
  pureNumber,
  disabled,
}: Props) => {
  return (
    <S.Container className={className}>
      <Input
        className="field-input"
        id={`${id} inputid`}
        name="inputName"
        onChange={onChange}
        value={value}
        placeholder={placeholder ?? " "}
        type={type}
        ref={ref}
        autoComplete="new-password"
        onKeyDown={(evt: any) =>
          type === "number" &&
          ((pureNumber && evt.key === ".") ||
            evt.key === "e" ||
            evt.key === "E" ||
            evt.key === "-" ||
            evt.key === "+" ||
            evt.key === ",") &&
          evt.preventDefault()
        }
        disabled={disabled}
      />
      <S.TextLine
        className={`field-placeholder ${
          placeholder || isTopLabel ? "show-text" : ""
        } ${disabled ? "disabled" : ""}`}
        htmlFor="inputName"
      >
        {text}
      </S.TextLine>
      {unittext && <S.UnitText>{unittext}</S.UnitText>}
    </S.Container>
  );
};

const InputSearch = ({
  type,
  text,
  className,
  onChange,
  value,
  searchText,
  placeholder,
  ref,
  onClick,
  onKeyPress,
}: Props) => {
  return (
    <S.Container className={className}>
      <Input
        className="field-input"
        id="inputid"
        name="inputName"
        onChange={onChange}
        value={value}
        placeholder={placeholder ?? " "}
        type={type}
        ref={ref}
        autoComplete="new-password"
        onKeyPress={onKeyPress}
      />
      <S.TextLine className="field-placeholder" htmlFor="inputName">
        {text}
      </S.TextLine>
      <S.TextSearch onClick={onClick}>{searchText}</S.TextSearch>
    </S.Container>
  );
};

const InputSearchIcon = ({
  type,
  text,
  className,
  id,
  onChange,
  value,
  searchIcon,
  placeholder,
  ref,
  onKeyPress,
  onBlur,
}: Props) => {
  return (
    <S.Container className={className}>
      <Input
        className="field-input"
        id="searchInput"
        name="searchInput"
        onChange={onChange}
        value={value}
        placeholder={placeholder ?? " "}
        type={type}
        ref={ref}
        autoComplete="new-password"
        onKeyPress={onKeyPress}
        onBlur={onBlur}
      />
      <S.TextLine className="field-placeholder" htmlFor="inputName">
        {text}
      </S.TextLine>
      <S.SearchIcon>{searchIcon}</S.SearchIcon>
    </S.Container>
  );
};

const InputButton = ({
  type,
  text,
  className,
  onChange,
  value,
  placeholder,
  ref,
}: Props) => {
  return (
    <S.Container className={className}>
      <Input
        suffix={text}
        onChange={onChange}
        value={value}
        placeholder={placeholder ?? " "}
        type={type}
        ref={ref}
        autoComplete="new-password"
      />
    </S.Container>
  );
};

const InputBase = ({
  type,
  className,
  onChange,
  value,
  placeholder,
  unittext,
  ref,
  onKeyDown,
  disabled,
  id,
}: Props) => {
  return (
    <S.Container className={className}>
      <Input
        onChange={onChange}
        value={value}
        placeholder={placeholder ?? " "}
        type={type}
        ref={ref}
        autoComplete="new-password"
        onKeyDown={onKeyDown}
        disabled={disabled}
        id={id}
      />
      <S.UnitText>{unittext}</S.UnitText>
    </S.Container>
  );
};

// const InputBottomLine = (props: Props) => {
//   const {
//     type,
//     text,
//     className,
//     onChange,
//     value,
//     placeholder,
//     pureNumber,
//     maxLength,
//   } = props;

//   return (
//     <S.BottomLine className={className}>
//       {type === "text" ? (
//         <Input
//           onChange={onChange}
//           value={value}
//           placeholder={placeholder ?? " "}
//           type={type}
//           autoComplete="new-password"
//         />
//       ) : (
//         <NumberFormat
//           onChange={onChange}
//           value={value}
//           type={type}
//           placeholder={placeholder ?? " "}
//           autoComplete="new-password"
//           thousandSeparator={true}
//           onKeyDown={(evt: any) =>
//             pureNumber &&
//             (evt.key === "." ||
//               evt.key === "e" ||
//               evt.key === "E" ||
//               evt.key === "-" ||
//               evt.key === "+") &&
//             evt.preventDefault()
//           }
//           maxLength={
//             maxLength ? maxLength + Math.floor((maxLength - 1) / 3) : 50
//           }
//           // {...props}
//         />
//       )}
//       <S.TextLabel>{text}</S.TextLabel>
//     </S.BottomLine>
//   );
// };

function InputMain(props: Props, ref: any) {
  const {
    id,
    btntype,
    type,
    text,
    className,
    onChange,
    value,
    searchText,
    searchIcon,
    unittext,
    placeholder,
    onKeyDown,
    disabled,
    onClick,
    suffixText,
    isTopLabel,
    onSuffixEvent,
    isDisabledSuffix,
    onKeyPress,
    onBlur,
    pureNumber,
    maxLength,
    mask,
  } = props;

  const renderInput = () => {
    switch (btntype) {
      case "BUTTON":
        return (
          <InputButton
            text={text}
            className={className}
            onChange={onChange}
            value={value}
            unittext={unittext}
            placeholder={placeholder}
            type={type}
            ref={ref}
          />
        );
      case "LINE":
        return (
          <InputLine
            id={id}
            text={text}
            className={className}
            onChange={onChange}
            value={value}
            unittext={unittext}
            placeholder={placeholder}
            type={type}
            isTopLabel={isTopLabel}
            pureNumber={pureNumber}
            disabled={disabled}
          />
        );
      case "SEARCH":
        return (
          <InputSearch
            text={text}
            searchText={searchText}
            className={className}
            onChange={onChange}
            value={value}
            unittext={unittext}
            placeholder={placeholder}
            type={type}
            onClick={onClick}
            onKeyPress={onKeyPress}
          />
        );

      // case "BOTTOMLINE":
      //   return (
      //     <InputBottomLine
      //       text={text}
      //       className={className}
      //       onChange={onChange}
      //       value={value}
      //       unittext={unittext}
      //       type={type}
      //       maxLength={maxLength}
      //       {...props}
      //     />
      //   );

      case "SEARCH_ICON":
        return (
          <InputSearchIcon
            text={text}
            searchIcon={searchIcon}
            className={className}
            onChange={onChange}
            value={value}
            unittext={unittext}
            placeholder={placeholder}
            type={type}
            onKeyPress={onKeyPress}
            onBlur={onBlur}
          />
        );

      case "BUTTON_LABEL":
        return (
          <InputButtonLabel
            text={text}
            className={className}
            onChange={onChange}
            value={value}
            unittext={unittext}
            placeholder={placeholder}
            type={type}
            suffixText={suffixText}
            isTopLabel={isTopLabel}
            onSuffixEvent={onSuffixEvent}
            isDisabledSuffix={isDisabledSuffix}
            disabled={disabled}
          />
        );

      // case "MONEY_LABEL":
      //   return (
      //     <InputMoneyLabel
      //       text={text}
      //       className={className}
      //       onChange={onChange}
      //       value={value}
      //       unittext={unittext}
      //       placeholder={placeholder}
      //       type={type}
      //       suffixText={suffixText}
      //       isTopLabel={isTopLabel}
      //       onSuffixEvent={onSuffixEvent}
      //       isDisabledSuffix={isDisabledSuffix}
      //       disabled={disabled}
      //     />
      //   );

      // case "HAVE_MASK":
      //   return (
      //     <InputMaskLabel
      //       text={text}
      //       className={className}
      //       onChange={onChange}
      //       value={value}
      //       unittext={unittext}
      //       placeholder={placeholder}
      //       type={type}
      //       suffixText={suffixText}
      //       isTopLabel={isTopLabel}
      //       onSuffixEvent={onSuffixEvent}
      //       isDisabledSuffix={isDisabledSuffix}
      //       disabled={disabled}
      //       mask={mask}
      //     />
      //   );

      default:
        return (
          <InputBase
            className={className}
            onChange={onChange}
            value={value}
            unittext={unittext}
            placeholder={placeholder}
            type={type}
            onKeyDown={onKeyDown}
            disabled={disabled}
            {...props}
          />
        );
    }
  };

  return renderInput() ?? null;
}

export default forwardRef(InputMain);
