import { mainAxios } from "libs/axios";

export const notificationApi = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/notify`,
    notError: true,
    requiresToken: true,
  });
};

interface visitNotifyProps {
  notifyId: number;
}

export const visitNotificationApi = (payload: visitNotifyProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/update-visit`,
    notError: true,
    requiresToken: true,
    payload: payload,
  });
};
