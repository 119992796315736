import {
  BellOutlined,
  DesktopOutlined,
  FileOutlined,
  HomeFilled,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  OneToOneOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Badge, Col, Layout, Menu, MenuProps, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";

import ImgNoti from "assets/images/notification.png";
import Avatar from "assets/images/avatar.png";
import En from "assets/images/lang-eng.png";
import Kr from "assets/images/lang-kr.png";
import LogoMin from "assets/images/logo-min.png";
import Logo from "assets/images/logo.png";

import { MultipleLanguage } from "components";
import { useTranslation } from "react-i18next";
import { cleanLocaStorage, saveLocalStorage } from "utils/localStorage";
import { LANG } from "utils/localStorage/type";
import * as S from "./style";

import { UrlInternal } from "common/constants/endpoints";
import { useHistory } from "react-router-dom";
import { store } from "redux/store";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducer";
import ModalNotify from "./ModalNotify";
import { notificationApi } from "apis";

interface Props {
  children?: React.ReactNode;
}

const { Header, Content, Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

function useOutsideAlerter(ref: any, setVisible: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisible();
        // setVisible(false);
        document.body.style.overflow = "auto";
        document.body.style.touchAction = "auto";
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setVisible]);
}

const Template = ({ children }: Props) => {
  //: React.FC =
  const [collapsed, setCollapsed] = useState(false);
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const dataUser: any = useSelector(
    (state: RootState) => state.userSlice.dataUser
  );
  let href: any = window.location.href.split("/");
  href = href[3];
  const [selectedRoutes, setSelectedRoutes] = useState("");
  const [visibleNotification, setVisibleNotification] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setVisibleNotification(false));
  const [dataNotify, setDataNotify] = useState<any>({});

  const getDataNotiFy = async () => {
    const res: any = await notificationApi();
    if (res) {
      setDataNotify(res);
    }
  };

  useEffect(() => {
    getDataNotiFy();
  }, []);

  useEffect(() => {
    if (href) {
      setSelectedRoutes("/" + href);
    }
  }, [href]);

  const items: MenuItem[] = [
    getItem(t("MENU_DASHBOARD"), UrlInternal.DASHBOARD, <PieChartOutlined />),
    getItem(t("CALENDAR"), UrlInternal.CALENDAR, <CalendarOutlined />),
    getItem(
      t("MENU_MANAGER_BORROWER"),
      UrlInternal.MANAGE_BORROWER,
      <DesktopOutlined />
    ),
    getItem(t("MENU_MANAGER_LOAN"), UrlInternal.LOAN, <UserOutlined />),
    getItem(t("MENU_MANAGER_MORTGAGE"), UrlInternal.MORTGAGE, <TeamOutlined />),
    getItem(
      t("MENU_MANAGER_REGISTER_LOAN"),
      UrlInternal.REGISTER_LOAN,
      <FileOutlined />
    ),
    getItem(t("MENU_FAQ"), UrlInternal.FAQ, <OneToOneOutlined />),
  ];

  const listOptions = [
    {
      src: Kr,
      name: "Korea",
      lang: "kr",
    },
    {
      src: En,
      name: "English",
      lang: "en",
    },
  ];

  const getLangSelected = (e: any) => {
    i18n.changeLanguage(e.lang);
    saveLocalStorage(LANG, e.lang, 7);
  };

  const handleLogout = () => {
    store.dispatch({ type: "logOut" });
    cleanLocaStorage();
    history.push(UrlInternal.LOGIN);
  };

  return (
    <S.Container>
      <Layout style={{ minHeight: "100vh" }}>
        <S.SliderBox
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          active={collapsed ? "true" : ""}
        >
          <div className="box-logo">
            <img
              src={collapsed ? LogoMin : Logo}
              alt="logo"
              className={collapsed ? "mini-logo" : ""}
              onClick={() => history.push(UrlInternal.DASHBOARD)}
            />
          </div>
          <Menu
            mode="inline"
            items={items}
            onClick={({ keyPath }) => {
              history.push(keyPath[0]);
              setSelectedRoutes(keyPath[0]);
            }}
            // defaultSelectedKeys={[href ? "/" + href : UrlInternal.DASHBOARD]}
            selectedKeys={[selectedRoutes]}
          />

          <hr className="hr-sider" />

          <div
            className="box-logout"
            style={{ justifyContent: collapsed ? "center" : "flex-start" }}
            onClick={handleLogout}
          >
            <LogoutOutlined />
            {!collapsed && <div>Logout</div>}
          </div>
        </S.SliderBox>
        <Layout className="site-layout">
          <Header>
            {/* <Input placeholder="Search..." suffix={<SearchOutlined />} /> */}
            <div
              className="btn-trigger"
              onClick={() => setCollapsed((prev) => !prev)}
            >
              {!collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            </div>
            <Row>
              <Col className="box-info">
                <Row gutter={[30, 0]}>
                  <Col
                    className="center-icon"
                    onClick={() =>
                      window.open("http://43.200.174.103", "_blank")
                    }
                  >
                    {/* <i className="ti ti-home" /> */}
                    <HomeFilled className="ti-home" />
                    {/* <HomeOutlined /> */}
                  </Col>
                  <Col className="center-icon">
                    {/* <HomeOutlined /> */}
                    <MultipleLanguage
                      langSelected={getLangSelected}
                      listOptions={listOptions}
                    />
                  </Col>
                </Row>
              </Col>
              <div className="hr-rotate">
                <div className="item-hr" />
              </div>
              <Col>
                <Row gutter={[15, 0]}>
                  <Col className="box-info">
                    <div className="name-admin">{dataUser?.userName}</div>
                    <div className="position-user">{dataUser?.userRole}</div>
                  </Col>
                  <Col>
                    <img src={Avatar} alt="avatar" className="avatar" />
                  </Col>
                </Row>
              </Col>
              <Col className="container-bell">
                <div className="box-bell">
                  <div onClick={() => setVisibleNotification((prev) => !prev)}>
                    <Badge count={dataNotify?.countNew ?? 0}>
                      <img src={ImgNoti} alt="img bell" />
                      {/* <div className="count">12</div> */}
                    </Badge>
                  </div>
                  {visibleNotification && dataNotify?.notify?.length > 0 && (
                    <div className="box-notification" ref={wrapperRef}>
                      <ModalNotify
                        data={dataNotify.notify}
                        callBackDataNotify={getDataNotiFy}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Header>
          <Content style={{ margin: "20px 20px 0" }}>{children}</Content>
        </Layout>
      </Layout>
    </S.Container>
  );
};

export default Template;
