import React, { useState } from "react";
import { Tabs } from "antd";

import * as S from "./style";

interface PropItem {
  dataItem?: any;
}

function TabItem({ dataItem }: PropItem) {
  return dataItem.label ? (
    <S.ItemTab className="item">
      <div className="label">{dataItem?.label}</div>
      <div className="value">{dataItem?.value}</div>
    </S.ItemTab>
  ) : (
    <S.ItemTab>{dataItem}</S.ItemTab>
  );
}

interface Props {
  tabDatas?: any;
  onChange?: any;
  activeKey?: string;
}

function TabComponent(props: Props) {
  const { tabDatas, onChange, activeKey } = props;
  
  return (
    <S.Container>
      <Tabs
        defaultActiveKey={activeKey ?? "0"}
        onChange={(e) => onChange(e)}
        items={tabDatas.map((data: any, index: any) => ({
          label: <TabItem dataItem={data} />,
          key: index,
        }))}
      />
    </S.Container>
  );
}

export default TabComponent;
